<template>
  <div class="pt-24 pb-20 md:pb-16 bg-white p-8 background--blue">
    <div class="container mx-auto text-left opacity-100 text-sm lg:px-4">
      <div
        class="flex flex-col justify-center items-start content-start lg:flex-row mb-4"
      >
        <div class="mb-6 md:mb-0 sm:w-1/4 h-auto">
          <!--            v-scroll-reveal="{ delay: 1000, distance: '5%' }"-->
          <a

            class="cursor-pointer inline-block w-8"
            href="/"
            rel="noopener noreferrer"
          >
            <img
              alt="WalletX Icon"
              class="w-8 grow"
              src="@/assets/img/icon/walletx_icon_w.png"
            />
          </a>
          <p
            class="mt-32 text-left gt-walsheim-regular text-grey text-sm opacity-50 hidden lg:block"
          >
            © ICONTROL Team
          </p>
        </div>
        <div class="sm:w-1/4 h-auto sm:mt-0 mt-8">
          <div class="gt-walsheim-medium text-lg text-grey-darkest mb-4">
            WalletX
          </div>
          <ul class="list-reset leading-normal cursor-pointer">
            <li class="mb-4 md:mb-2">
              <a
                class="link open-sans-regular text-grey-darker hover-link"
                href=""
                target="_blank"
                rel="noopener noreferrer"
                >Download the iOS app</a
              >
            </li>
            <li class="mb-4 md:mb-2">
              <a
                class="link open-sans-regular text-grey-darker hover-link"
                href=""
                target="_blank"
                rel="noopener noreferrer"
                >Download the Android app</a
              >
            </li>
          </ul>
        </div>
        <div class="sm:w-1/4 h-auto mt-8 lg:mt-0">
          <div class="gt-walsheim-medium text-lg text-grey-darkest mb-4">
            Help
          </div>
          <ul class="list-reset leading-normal cursor-pointer">
            <li class="mb-4 md:mb-2">
              <a
                class="link open-sans-regular text-grey-darker hover-link"
                href="mailto:icontrol.b@gmail.com?Subject=I%27m%20looking%20for%20a%201%3A1%20WalletX%20onboarding%20session&Body=%5BPlease%20let%20us%20know%20your%20availability%5D"
                target="_blank"
                rel="noopener noreferrer"
                >Onboard 1:1</a
              >
            </li>
            <li class="mb-4 md:mb-2">
              <a
                class="link open-sans-regular text-grey-darker hover-link"
                href="mailto:icontrol.b@gmail.com?Subject=I%27m%20having%20trouble%20with%20WalletX&Body=%5BPlease%20describe%20your%20issue%5D"
                target="_blank"
                rel="noopener noreferrer"
                >App support
              </a>
            </li>
            <li class="mb-4 md:mb-2">
              <a
                class="link open-sans-regular text-grey-darker hover-link"
                href=""
                target="_blank"
                rel="noopener noreferrer"
                >Roadmap</a
              >
            </li>
          </ul>
        </div>
        <div>
          <transition appear name="fade">
            <div class="mt-12 mt-4 lg:mt-0">
              <a
                class="opacity-100 inline-block w-8 mr-6 md:mr-4 grow"
                href="https://github.com/icontrol-team"
                target="_blank"
                rel="noopener noreferrer"
                title="GitHub"
              >
                <img
                  alt="Github Icon"
                  class="w-8"
                  src="@/assets/svg/logo-github.svg"
                />
              </a>
              <a
                class="opacity-100 inline-block w-8 mr-6 md:mr-4 grow"
                href="https://twitter.com/icontrol_team"
                target="_blank"
                rel="noopener noreferrer"
                title="Twitter"
              >
                <img
                  alt="Twitter Icon"
                  class="w-14"
                  src="@/assets/svg/logo-twitter.svg"
                />
              </a>
              <a
                class="opacity-100 inline-block w-8 mr-6 md:mr-4 grow"
                href=""
                target="_blank"
                rel="noopener noreferrer"
                title="Discord"
              >
              </a>

            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
};
</script>
