<template>
  <div class="about">
    <h1>This is an about page 다국어 테스트</h1>
    <p>{{ $t('message.hello') }}</p>
    <p>{{ $t("hello") }}</p>
    <button @click="$i18n.setLocale('ko')">한국어</button>
    <button @click="$i18n.setLocale('en')">English</button>

    <div style="    position: relative;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;">

    <div style="position: relative;
    ">

      <div class="main-title"   style="z-index: 999;position: relative;display: flex">
        <h2>Vue-Particles</h2> <p class="subtitle">
          Vue.js component for particles backgrounds
        </p> <p class="subtitle">
          SSR compatible
        </p> <br> <a target="_blank" href="https://github.com/creotip/vue-particles" class="gh-button"><span class="gh-button__icon"></span>Download</a>
      </div>

      <vue-particles style="position: relative;display: flex;position: absolute;top: 0;left: 0;width: 100%;height: 100%;"
        color="#dedede"
        :particleOpacity="0.7"
        :particlesNumber="10"
        shapeType="circle"
        :particleSize="30"
        linesColor="#dedede"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="150"
        :moveSpeed="3"
        :hoverEffect="true"
        hoverMode="grab"
        :clickEffect="true"
        clickMode="push"
      >
      </vue-particles>
    </div>

    </div>

  </div>


</template>

<script>
import { useI18n } from "vue3-i18n";
export default {
  name: "About.vue",
  setup() {
    const i18n = useI18n();
    const setLocale = (lang) => {
      i18n.setLocale(lang);
    };

    return {
      setLocale,
    };
  },

};
</script>
