

const ExceptionCode = {
    0: 'DATA ERROR',
    1: 'FORMAT ERROR',
    2: 'WALLET ERROR',
    3: 'RPC ERROR',
    4: 'SCORE ERROR',
    5: 'NETWORK ERROR',
    6: 'SETTING ERROR'
};



/**
 * Class representing the Exception
 */
export class Exception {
    /**
     * Creates an instance of Exception.
     * @param {string} code The exception code.
     * @param {string} message The exception message.
     */
    constructor(code, message) {
        this.code = code;
        this.message = message;
    }

    /**
     * Converts Exception instance to string.
     * @return The exception string.
     */
    toString() {
        return `[${this.code}] ${this.message}`;
    }
}


/**
 * The exception class relate to data type.
 * @extends {Exception}
 */
export class SettingError extends Exception {
    /**
     * Creates an instance of DataError.
     * @param {string} message The exception message.
     */
    constructor(message) {
        super(ExceptionCode[6], message);
    }
}

/**
 * The exception class relate to format.
 * @extends {Exception}
 */
export class FormatError extends Exception {
    /**
     * Creates an instance of FormatError.
     * @param {string} message The exception message.
     */
    constructor(message) {
        super(ExceptionCode[1], message);
    }
}


export class NetworkError extends Exception {
    constructor(message, throwException=null) {
        if (throwException) {
            throw super(ExceptionCode[5], message).toString();
        }else{
            super(ExceptionCode[5], message);
        }

    }
}
