<template>
  <button class="Button" v-bind:isHidden="isHidden" @click="callback($event)">
    <slot></slot>
    <i v-if="isHidden" class="material-icons align-text-top">keyboard_arrow_down</i>
    <i v-else class="material-icons align-text-top">keyboard_arrow_up</i>

  </button>
</template>
<script>
export default {
  name: "HideButton",
  data() {
      return {
        count: 0,
        isHidden: false,
        // isHidden: this.hidden_layer
      }
  },
  methods: {
    callback: function(e) {
      this.count++;
      if (this.isHidden) {
        this.isHidden = false;
      }else{
        this.isHidden = true;
      }
      this.$emit('parent_method', this.isHidden);
    }
  },
  props: {
    hidden_layer: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>

