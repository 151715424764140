<template>
  <div id="app">
<!--    <div id='nav'>-->
<!--      <router-link to='/'> Home</router-link>-->
<!--      <router-link to='/about'>about </router-link>-->
<!--    </div>-->

    <router-view />
  </div>
</template>
<script>

export default {
  // setup() {
  //   const i18n = useI18n();
  //   const setLocale = (lang) => {
  //     i18n.setLocale(lang);
  //   };
  //
  //   return {
  //     setLocale,
  //   };
  // },
  created () {
    let locale = navigator.language || navigator.userLanguage
    locale = locale.substring(0, 2)
    if (locale !== 'ko') locale = 'en' // 한국어가 아닌 경우 무조건 영어로 설정
    this.$i18n.locale = locale
    console.log("locale:", locale, this.$i18n.locale);
  }
};
</script>
