<template>
  <div class="">
    <nav
      class="container mx-auto flex items-center justify-between flex-wrap pt-8 px-6 md:px-12"
    >
      <div class="flex items-center flex-shrink-0 text-black mr-6">
        <transition appear name="fadeDown">
          <a
            class="cursor-pointer inline-block w-50"
            href="/"
            rel="noopener noreferrer"
          >
            <img
              alt="ICONTROL logo"
              style="width: 180px"
              src="@/assets/img/icontrol.png"
            />
          </a>
        </transition>
      </div>
      <div class="hidden lg:block lg:inline-flex lg:items-center lg:w-auto mr-20">
        <div class="text-sm lg:flex-grow">
          <a
            href="#ins"
            v-smooth-scroll="{ duration: 1000, offset: 50 }"
            class="block mt-4 lg:inline-block lg:mt-0 cursor-pointer gt-walsheim-regular text-black hover-link mr-5"
          >
            INS
          </a>
          <a
            href="#features"
            v-smooth-scroll="{ duration: 1000 }"
            class="block mt-4 lg:inline-block lg:mt-0 cursor-pointer gt-walsheim-regular text-black hover-link mr-5"
          >
            Features
          </a>

        </div>
      </div>
      <div class="absolute right-0 mt-0 py-2 md:px-12">
        <select
          v-model="selected_locale"
          class="custom-select"
          v-bind:id="input_id"
          @change="$i18n.setLocale($event.target.value)"
        >
          <option v-for="option in options" v-bind:key="option.value">{{ option.text }}</option>
        </select>
<!--        <br/>-->
<!--        <span>Selected: {{ selected_locale }}</span>-->
<!--        <span>{{ $t("message.hello") }}</span>-->

      </div>
    </nav>
  </div>
</template>

<script>
import { useI18n } from "vue3-i18n";



export default {
  name: "Header",
  data() {
    return {
      selected_locale: "locale",
      options: [
        { text: "en", value: "en"},
        { text: "ko", value: "ko"},
      ]
    };
  },

  props: ['value', 'items', 'input_id'],

  methods: {
    updateLocale (event) {
      const i18n = useI18n();
      i18n.locale = event.target.value;
      // i18n.setLocale(event.target.value)
    }
  },
  mounted() {
    // const i18n = useI18n();
    let locale = navigator.language || navigator.userLanguage
    locale = locale.substring(0, 2)
    if (locale !== 'ko') locale = 'en' // 한국어가 아닌 경우 무조건 영어로 설정
    //
    // console.log("locale:: ", locale);
    // i18n.setLocale(locale)
    // this.selected_locale = locale;
    this.selected_locale = locale;
  },
  setup() {
    const i18n = useI18n();
    let locale = navigator.language || navigator.userLanguage
    locale = locale.substring(0, 2)
    if (locale !== 'ko') locale = 'en' // 한국어가 아닌 경우 무조건 영어로 설정

    console.log("locale:: ", locale);
    i18n.setLocale(locale)


    const setLocale = (lang) => {
      i18n.setLocale(lang);
    };

    return {
      setLocale,
    };
  },



};
</script>
