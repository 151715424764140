import { SettingError, NetworkError } from './Exception';
const axios = require("axios");
const utils = require('./utils');

const default_network_info = {
    mainnet: {
        url: "https://ctz.solidwallet.io",
        nid: "0x1",
        score_address: "cxccdec2d5a8072bf24478d58d86caf457b6b91ff7"
    },
    bicon: {
        url: "https://bicon.net.solidwallet.io",
        nid: "0x3",
        score_address: "cxd152d423789040a04a0e651285e4de90d0c9778a"
    },
    // invalidAddress: {
    //     url: "https://bicon.net.solidwallet.io",
    //     nid: "0x3",
    //     score_address: "cx7c03613dcf71dc1d10ac3f854338e89f4061a7b3"
    // }
}

const DEFAULT_HEADERS = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
}

class Response {
    /**
     * Create a new Response object
     *
     * @param {Request} req The Request that this Response is associated with
     * @param {Object} res The data representing the result of the request
     */
    constructor(req, res) {
        this.config = req.config
        this.data = res.responseText || res.response;
        this.status = res.status
        this.statusText = res.statusText

        /* lowecase all headers keys to be consistent with Axios */
        if ('headers' in res) {
            let newHeaders = {};
            for (let header in res.headers) {
                newHeaders[header.toLowerCase()] = res.headers[header];
            }
            res.headers = newHeaders;
        }
        this.headers = res.headers
        this.request = req
        this.code = res.code
    }
}

export class INS {

    constructor({network = "mainnet", params = {}, debug = false, network_info=default_network_info} = {}) {
        this.endpoint_url = ''
        this.address = ''
        this.score_address = ''
        this.network = network
        this.debug = debug
        // this.return_data = {}
        this.return_data = {
            request: {
                method: "",
                params: {}
            },
            response: {},
            status_code: ""
        }
        // this.params = Object.assign({
        //     id: 2848,
        //     jsonrpc: '2.0',
        // }, params)
        // this.params ={
        //     id: 2848,
        //     jsonrpc: '2.0',
        // }

        // this.setJsonRpc()
        // console.log("------->>> ", this.params);

        if (network_info[network]) {
            this.endpoint_url = `${network_info[network].url}/api/v3` ;
            this.score_address = network_info[network].score_address;
            this.nid = network_info[network].nid;
            if (this.debug) {
                console.log("============ DEBUG MODE ============");
                console.log("network: ", this.network);
                console.log("endpoint_url: ", this.endpoint_url);
                console.log("score_address: ", this.score_address);
                console.log("nid: ", this.nid);
                console.log("params = ", this.params);
            }
        }else{
            const error = new SettingError(`"${network}" is not valid network name. (mainnet|testnet|bicon)`);
            throw error.toString();
        }
    }

    setJsonRpc(){
        this.params = {
            id: 2848,
            jsonrpc: '2.0',
        }
    }

    setParams(params = {}, data ={}) {
        this.setJsonRpc()
        this.params =  Object.assign({}, this.params, data, params);
    }

    setFields(data = {}) {
        for (const field in data) {
            this[field] = data[field];
        }
    }

    createPayload(data, id=2848) {
        this.params =  Object.assign({}, {
            id: id,
            jsonrpc: '2.0',
            // params: []
        }, data);

    }

    setCallParam(method=null, params={}){
         this.return_data.request = {
             params: params,
             method: method
         }

         // console.log("===== setCallParams", this.return_data);

         this.setParams({
                "method": "icx_call",
                "params": {
                    "from": "hx0000000000000000000000000000000000000000",
                    "to": this.score_address,
                    "dataType": "call",
                    "data": {
                        "method": method,
                        "params": params
                    }
                }
            }
        )
    }

    async getAddress(address = null, callback=null) {
        let method_name = "getAddress";
        if (address) {
            this.setCallParam(method_name, {"_ins_name": address})
        }else{
            console.log("ERROR getAddress()  address is null")
        }
        if (this.debug) {
            console.log(`${method_name}() -> ${address} `, this.params)
        }
        await this._get({method: method_name, address: address, callback});
        // this._setReturnResponse()

        return this.return_data
    }


    async getNameStatus(address = null, callback=null) {
        let method_name = "getNameStatus";
        if (address) {
            this.setCallParam(method_name, {"_ins_name": address})
        }
        if (this.debug) {
            console.log(`------ ${method_name} -> ${address} `, this.params)
        }
        let result = {};
        result = await this._get({method: method_name, address: address, callback})
        // console.log( "**** getNameStatus :: ", result);

        // try {
        //     if (result.response.result.name === ""){
        //         if (result.response.result.address === "") {
        //             result.response.result = {
        //                 message: `"${address}" is not found`
        //             }
        //         }
        //         result.status_code = 400;
        //     }else{
        //         result.response.result = this.ChangeUnix2DateString(
        //             result.response.result,
        //             Array("address_update_date", "creation_date", "expiry_date", "update_date")
        //         );
        //     }
        // }catch(e) {
        //     console.log("** ERROR ** ", e);
        //
        // }
        // this.return_data.response = ;
        // this._setReturnResponse(result);
        return this.return_data;
    }

    async getNamesByAddress(address = null, callback=null) {

        // let method_name = this.getMethodName();
        let method_name = utils.getFuncName(true);

        if (address) {
            this.setCallParam(method_name, {"_address": address})
        }
        if (this.debug) {
            console.log(`${method_name}() -> ${address} `, this.params)
        }
        await this._get({method: method_name, address: address, callback})
        return this.return_data
    }

    _setReturnResponse(){
        let _response_raw = this.return_data._response_raw;
        let _response = this.return_data._response_raw.response;
        let request = this.return_data.request;
        let method = request.method;

        this.return_data.response = {result: ""}
        let return_response = this.return_data.response;

        // console.log("*** _setReturnResponse() method:" , method)
        switch (method){
            case "getNameStatus":
                let address = request.params._ins_name;

                try {
                    if (_response.result.name === ""){
                        if (_response.result.address === "") {
                            return_response.result = {
                                message: `"${address}" is not found`
                            }
                        }
                        this.return_data.status_code = 400;
                    }else{
                        return_response.result = this.ChangeUnix2DateString(
                            _response.result,
                            Array("address_update_date", "creation_date", "expiry_date", "update_date", "reservation_end_date")
                        );
                    }
                }catch(e) {
                    console.log("** ERROR ** ", e);
                }
                break

            default:
                if (_response_raw) {
                    console.log("JJJJJ---- _response_raw:", _response_raw);

                    console.log(_response);

                    this.return_data.response.result = _response.result;
                    console.log("------- switch::", this.return_data.response)
                }
                break
        }

    }

    // Array("address_update_date", "creation_date", "expiry_date")

    toTable({elem=null, title} = {}){
        let response = this.return_data.response.result
        console.log("toTable():", response)
        utils.generate_table({

                // element: "table",
                element_id: elem,
                output: elem,
                data: response,
                options: {
                    class:"ins-result-table",
                    caption: "INS Result"
                }
            }
        );

    }
    ChangeUnix2DateString(object, dest_array){
        for (let key of dest_array){
            if (object[key]) {
                object[key] = utils.unixtime2date(object[key]);
            }
        }
        return object
    }

    async _get({method= "getAddress", address="", callback=null}) {
        // if (address){
        //     this.setCallParam(method, {"_ins_name": address})
        // }

        // this.return_data._response_raw = {};


        let result = ""
        if (callback) {
            await this.call_api({
                endpoint_api: this.endpoint_url,
                payload: this.params,
                method: "POST",
                request: {method: method, address: address}
            }).then(callback);
        }else{
            result = await this.call_api({
                endpoint_api: this.endpoint_url,
                payload: this.params,
                method: "POST",
                request: {method: method, address: address}
            })
            this.return_data._response_raw = result
        }

        // this.return_data.response = result
        this._setReturnResponse();
        return result
    }

    async call_api({endpoint_api, payload, method, request={}} = {}) {

        let options = {
            url: endpoint_api,
            method: method || "POST",
            credentials: true,
            headers: {
                "Content-Type": "application/json",
                // "Content-Type": "application/x-www-form-urlencoded"
            },
            data: payload,
        };
        // console.log("options:",  options, "request:", request);
        let response, response_data;
        let return_data = {
            request: request,

        };
        try {
            response = await axios(options);
            try {
                response_data = await response.data;
                if (response.status) {
                    this.return_data.status_code = response.status;
                }
            }catch(error) {
                response_data = {"error": response};

            }

        } catch (error){
            // console.log("Got error", error.errno, error.syscall );
            // console.log("Got error[url]", error.config.url );
            // console.log("Got error[data]", error.config.data );
            // console.log("Got error[method]", error.config.method );
            let error_status, error_message;
            try{
                error_status = error.response.status;
            }catch (e){
                error_status = undefined
            }

            this.return_data.status_code = error_status;

            try {
                error_message = error.response.data.error.message
            } catch (e){
                error_message = undefined
            }

            return_data.response =  {
                "result": {
                    "message": error_message
                }
            };
            return_data.status_code = error_status;


            console.log(return_data);
            if (error_status) {
                return return_data
            }

            throw new NetworkError(`"${error}", url=${error.config.url}, data=${error.config.data} connect error`).toString();
            // throw a.toString();
        }

        let responseOK = response && response.status === 200;
        if (responseOK) {
            console.log("SUCCESS");
        }else{
            console.log("FAIL");
        }
        return_data.status_code = response.status;
        // this.response.status_code = response.status;
        return_data.response = this.cleanup_obj(response_data);
        // console.log("data::", return_data);

        return return_data;
    }

    cleanup_obj(object) {
        let remove_keys = Array("id", "jsonrpc")
        for (let key of remove_keys){
            if (object[key]) {
                delete object[key];
            }
        }
        return object;
    }
}


function createInstance(defaultConfig) {
    var context = new INS(defaultConfig);
    var instance = bind(Axios.prototype.request, context);

    // Copy axios.prototype to instance
    utils.extend(instance, Axios.prototype, context);
    utils.extend(instance, Axios.prototype, context);

    // Copy context to instance
    utils.extend(instance, context);

    return instance;
}

export function obj2Table({elem=null, title, data} = {}){
    utils.generate_table({
            element_id: elem,
            output: elem,
            data: data,
            options: {
                class:"ins-result-table",
                // caption: "INS Result"
            }
        }
    );

}
// module.exports = {
//     INS
// }
// module.exports.INS = INS;
//
// module.exports.default = INS;

// export default INS;
export function init(){
    console.log("init")
}

// var ins_sdk = new INS({});

// exports.INS = INS;


if (typeof(exports) !== "undefined") {
    exports.INS = INS;
}

// export default INS;

// module.exports = INS;
// module.exports = ins_sdk;
// // module.exports = INS;
// // module.exports.default = INS;
// module.exports.default = ins_sdk;




