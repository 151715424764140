import { createRouter, createWebHistory } from 'vue-router'
import Home from "./views/Home.vue";
import About from "./views/About.vue";

const routerHistory = createWebHistory()

const router = createRouter({
    history: routerHistory,
    routes: [
        {
            path: "/",
            // name: "home",
            component: Home
        },
        // {
        //     path: "/about",
        //     // name: "about",
        //     // route level code-splitting
        //     // this generates a separate chunk (about.[hash].js) for this route
        //     // which is lazy-loaded when the route is visited.
        //     component: About
        //     // component: () => import(/* webpackChunkName: "about" */ "./views/About")
        // }
    ]
});


// const router = createRouter({
//     history: routerHistory,
//     routes: [
//         {
//             path: '/',
//             component: Home
//         },
//         {
//             path: '/contact',
//             component: About
//         }
//     ]
// })

export default router
