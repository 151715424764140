
function forEach(obj, fn) {
    // Don't bother if no value provided
    if (obj === null || typeof obj === 'undefined') {
        return;
    }

    // Force an array if not already something iterable
    if (typeof obj !== 'object') {
        /*eslint no-param-reassign:0*/
        obj = [obj];
    }

    if (isArray(obj)) {
        // Iterate over array values
        for (var i = 0, l = obj.length; i < l; i++) {
            fn.call(null, obj[i], i, obj);
        }
    } else {
        // Iterate over object keys
        for (var key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                fn.call(null, obj[key], key, obj);
            }
        }
    }
}

function isArray(val) {
    return toString.call(val) === '[object Array]';
}

function extend(a, b, thisArg) {
    forEach(b, function assignValue(val, key) {
        if (thisArg && typeof val === 'function') {
            a[key] = bind(val, thisArg);
        } else {
            a[key] = val;
        }
    });
    return a;
}


function unixtime2date(unixtimestamp) {
    let date = new Date(parseInt(unixtimestamp)* 1000);
    let year = date.getFullYear();
    let month = ("0" + (date.getMonth() + 1)).substr(-2);
    let day = ("0" + date.getDate()).substr(-2);
    let hour = ("0" + date.getHours()).substr(-2);
    let minutes = ("0" + date.getMinutes()).substr(-2);
    let seconds = ("0" + date.getSeconds()).substr(-2);

    return year + "-" + month + "-" + day + " " + hour + ":" + minutes + ":" + seconds;
}

function makeElement(document, type, className, id, parent) {
    const el = document.createElement(type);
    // el.style.visibility = "visible";
    if (id){
        el.id = id;
    }
    if (className) {
        el.className = className;
    }
    if (parent) {
        parent.appendChild(el);
    }
    return el;
}

function setDefaultParams(options, object){
    options = options || {};
    for (let prop in options)  {
        object[prop] = typeof object[prop] !== 'undefined' ? object[prop] : options[prop];
    }
    return object
}

function reverseObject(object) {
    let newObject = {};
    let keys = [];
    for (let key in object) {
        keys.push(key);
    }
    for (let i = keys.length - 1; i >= 0; i--) {
        let value = object[keys[i]];
        newObject[keys[i]]= value;
    }
    return newObject;
}

function arrayContains(needle, array_stack) {
    return (array_stack.indexOf(needle) > -1);
}


// function get_functionName(fun) {
//     let ret = fun.toString();
//     ret = ret.substr('function '.length);
//     ret = ret.substr(0, ret.indexOf('('));
//     return ret;
// }

function getFuncName(last_function=true) {
    let e = new Error('dummy');
    let result_stack = e.stack
        .split('\n')[2]
        // " at functionName ( ..." => "functionName"
        .replace(/^\s+at\s+(.+?)\s.+/g, '$1' );
    if (last_function) {
        let stack_arr = result_stack.split(".")
        console.log("stack_arr:", stack_arr);
        return stack_arr[stack_arr.length-1]
    }
    return result_stack
}


// function get_functionName() {
//     let re = /function (.*?)\(/
//     let s = get_functionName.caller.toString();
//     let m = re.exec( s )
//     return m[1];
//}

function generate_table({element_id, output, data, options}={}){
    options = options || {};
    let dest_element = window.document.getElementById(element_id);
    let table = "";
    let table_raw = "";
    let key_style = "";
    let disabled_string;
    if (typeof data !== "object") {
        data = {result: data}
    }
    try {

        if (dest_element.hasChildNodes()) {
            dest_element.innerHTML = "";
        }

        if (dest_element) {
            table = makeElement(document, "table", options.class)
            if (data === undefined) {
                console.log("[ERROR] not found element: ", element_id)
                return
            }
        }
        // if (options.class){
        //     table.className = options.class;
        // }
        if (data.result === "NULL") {
            data = options.default_data;
        }else if (options.default_data){
            data = setDefaultParams(options.default_data, data);
        }
        if (options.reverse) {
            data = reverseObject(data);
        }
        Object.entries(data).forEach(([key, value]) => {
            if (typeof value === "object") {
                value = JSON.stringify(value);
            }
            if (options.editable) {
                if( options.disable_keys && arrayContains(key, options.disable_keys) ){
                    disabled_string = "disabled";
                }else{
                    disabled_string = "";
                }
                let className = "input_default";
                let autocomplete = "on";
                if (key === "password") {
                    className = "password";
                    autocomplete = "off";
                }
                value = "<input type='text' class="+className+" class='gray_input' id='"+key+"' value='"+value+"' "+disabled_string+ " autocomplete=" + autocomplete + "/>";
                key_style = "width:30px";
            }
            table_raw += "<tr><td class='table_key' style='"+key_style+"'>" + key + "</td><td>" + value + "</td></tr>";
        })
        if (options.caption) {
            table_raw += "<caption>" + options.caption + "</caption>";
        }
        table.innerHTML = table_raw;

        if (dest_element) {
            dest_element.appendChild(table);
        }else{
            dest_element.innerHTML = table;
        }

        if (options.editable_event) {
            dest_element.addEventListener("change", options.editable_event)
        }
        // }
    }catch (e){
        console.log(e);
    }
}

module.exports = {
    getFuncName: getFuncName,
    makeElement: makeElement,
    generate_table: generate_table,
    unixtime2date: unixtime2date,
    isArray: isArray,
    forEach: forEach,
    extend: extend,
};
