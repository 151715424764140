// import INS from './lib/INS';

// const ins = new INS({});
// export {ins as INS};

// module.exports = require('./lib/INS');

// export {INS};
//
// export default {
//     INS: INS
// };

const INS_API = require("./lib/INS");
module.exports = INS_API;
// console.log( INS.default);
// export { INS as default}

