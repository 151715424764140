import { createI18n } from "vue3-i18n";


// eslint-disable-next-line camelcase
const messages = require('@/locales/messages');
// const messages = {
//   en, ko
// }



// console.log(locale_messages);

// function loadLocaleMessages () {
//   const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
//     // const messages = {}
//   // eslint-disable-next-line global-require
//   const messages = require('@/locales/messages');
//
//   locales.keys().forEach(key => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i)
//     if (matched && matched.length > 1) {
//       const locale = matched[1]
//       messages[locale] = locales(key)
//     }
//   })
//   return messages
// }





const i18n = createI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    // locale:'en',
    messages: messages,
})

export default i18n


// export default new VueI18n({
//   locale: process.env.VUE_APP_I18N_LOCALE || 'en',
//   fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
//   messages,
//   silentTranslationWarn: true
// })
