const messages = {
    en: {
        message: {
            hello: '< hello, buddy! >',
            secure: 'Secure',
            main_title: 'Wallet for Managing Crypto',
            main_sub_title: 'Easily store, transact, and stake in one wallet.<br>' +
                'Where you can do everything with ICX.',
            main_comming: 'It\'s available for downloading' ,
            introducing: 'Depositing and withdrawing ICX is simple and safe on WalletX, '+
                'just like a banking app.<br>' +
                'As one framework, it can be used for the ICON Network. ',
            ins_title: 'Say goodbye to long HX wallet addresses,\nthanks to ICON Name Service (INS). \n' +
                'Create your own INS address to instantly verify your identity\n'+
                'and easily share it with others. ',
            ins_is_avail: 'See if a name is available!',
            ins_input_placeholder: 'Type james and see what happens.',
            naming_rules: 'Naming Rules',
            naming_rules_hide: 'How to set up the best name',
            naming_rules_detail: '- <span class="bg-blue-200 rounded px-1 mx-1">a-z</span>,' +
                '<span class="bg-blue-200 rounded px-1 mx-1"> 0-9</span>, and hyphen (<span class="bg-blue-200 rounded px-1 mx-1">-</span>) is Available. <br>\n' +
                '- When using a hyphen(<span class="bg-blue-200 rounded px-1 mx-1">-</span>),  it can not be used at the beginning and the end of a name. <br>\n' +
                '- An <span class="bg-red-200 rounded px-1 mx-1">hx</span> string  can not be used at the beginning. <br>\n' +
                '- Space and special characters (such as !, $, &, _ and so on) not permitted. <br>' +
                '- The minimum length is 4 and the maximum length is 64 characters. <br>' +
                '-  Names are not case-sensitive.(i.e. you may use a mix of upper or lower case letters) <br>',
            pricing: 'Pricing',
            pricing_hide: 'Period of Usage by Ownership Fee',
            reward_card_desc: 'The Reward Program is designed to allow INS users to win big rewards. <br>' +
                'Using the Reward Card, you can immediately claim the entire accumulated reward if you win.<br><br> '+
                '<span class="ubuntu-bold text-2xl">How do I receive the Reward Card? </span>' +
                '<br>'+
                '<div class="ml-3">You receive the Reward Card when you purchase an INS. <br>'+
                '1 Reward Card has the equivalent value of 1 ICX (half of INS profit).<br>' +
                '<ul class="list-disc list-inside text-gray-500 -pl-10">' +
                '<li ><i class="md:text-sm">Example: If you purchase 2 INS for 12 months each, '+
                'you receive 24 Reward Cards in total (2 INS * 12 Months). </i></div><br><br>' +
                '<span class="ubuntu-bold text-2xl">How do I participate?  </span></li> </ul>' +
                '<br>'+
                '<div class="ml-3">1 Reward Card allows you to participate once in any round you want to participate in, '+
                ' so the more reward cards you own, the higher the probability of winning the round. <br>'+
                'The accumulated reward amount and the draw lot will be transparently revealed through ICON Network’s SCORE.</div> <br><br>'+
                '<span class="ubuntu-bold text-2xl">How do I win?   </span> <br>' +
                '<div class="ml-3">For every round, the SCORE randomly generates a winning TX (transaction ID) and a random TX for the participant.<br>'+
                ' Depending on the difficulty we set, if the winning TX and the participant’s TX matches, YOU WIN! <br>'+
                'If there is no winner for the previous round, the accumulated funds will be carried over to the next round. <br> '+

                '<ul class="list-disc mt-0 mb-0 list-inside text-gray-500">' +
                '<li class="md:text-sm"> In the beginning, we are planning on lowering the difficulty of winning (the Hash digit to match) to increase the chance of winning.  </li>' +
                '<li class="md:text-sm"> For every entry, <span class="bg-red-200 rounded px-1 mx-1">1 ICX</span> is added; the final winner wins all of the ICX accumulated in that round.</li>' +
                '<li class="md:text-sm"> <i>Example: If 100 participants enter a round, the accumulated ICX for that round is previous round reward '+
                '<span class="bg-red-200 rounded px-1 mx-1">+ 100 ICX</span>.' +
                ' You will immediately be notified whether you won the round or not. </i> </li></ul></div>',
            security_title: "Multiple Layers of Security",
            security_desc: "WalletX offers multiple ways to ensure your funds are safe and transactions are secure,\n" +
                           "such as fingerprint authentication.",
            notification_title: "Notification",
            notification_desc: "To make it easier to check updates to my assets, WalletX sends you push notifications according to user settings,"+
                               " just like a mobile banking service. Users can receive real-time notifications of deposit and withdrawal.",

            icontrol_intro: 'The ICONTROL team designs, develops, and plans projects to change the world one by one through blockchain.'+
                ' As an ardent supporter of the ICON Network, our team was formed to contribute to the ICON ecosystem. <br>' +
                'WalletX is the product of our aspiration to create the most secure and convenient cryptocurrency wallet. <br>' +
                'WalletX will also be the platform to make blockchain more approachable and practicable to many people.<br>'+
                'So please support us and look forward to what we will add in the future! <br>'+
                'Thank you for your support!'


        },
        INS: {
            invalid_ins: "Invalid rules",
            avail_ins: "is Available!",
            reserved_ins: "Reserved INS",
            used_ins: "Used INS",
            not_allowed_characters: "is not permitted",
            not_allowed_hx_string: "cannot start hx string",
            not_allowed_cx_string: "cannot start cx string",
            not_allowed_blank: "blank is not permitted",
            length_error: ", bigger than 4 and less than 64",
            hyphen_error: " Hyphen is not allowed at the beginning and end.",
        },
    },
    ko: {
        INS: {
            invalid_ins: "사용할 수 없는 INS",
            avail_ins: "는 사용할 수 있습니다!",
            used_ins: "사용 중인 INS",
            reserved_ins: "예약 중인 INS",
            not_allowed_characters: "허용하지 않는 문자를 포함하고 있습니다.",
            not_allowed_hx_string: "hx로 시작할 수 없습니다.",
            not_allowed_cx_string: "cx로 시작할 수 없습니다.",
            not_allowed_blank: "공백은 사용할 수 없습니다.",
            length_error: ", 4보다 크거나 64보다 작아야합니다.",
            hyphen_error: " 처음과 끝에는 hypen 허용이 안됩니다.",
        },
        message: {
            hello: '< 안녕 얘들아! >',
            secure: 'Crypto',
            main_title: '관리를 위한 안전한 지갑',
            // main_sub_title: '쉽게 저장, 전송하고 스테이킹을 하나의 지갑에서<br>' +
            //                 '내 모든 ICX를, WalletX에서 간편하게 ',
            main_sub_title: '내 모든 ICX를 WalletX에서 간편하게 저장, 전송하고 스테이킹하세요.',

            main_comming: '다운로드 가능합니다. ',
            introducing: "WalletX는 은행 앱처럼 쉽고 안전하게 사용할 수 있습니다.<br>" +
                "이제부터는 쉽고 간편하게 ICX를 송금하고 입금받으세요<br>" +
                "더불어 하나의 Framework로써  ICON Network를 편리하게 사용할 수 있습니다.",
            ins_title: "INS를 이용하면, 더 이상 길고 어려운 HX 주소를 외울 필요가 없습니다.\n" +
                        "나만의 INS 주소를 소유해서 증명하고, 빠르고 쉽게 공유하세요.",
            ins_is_avail: '사용할 수 있는 이름을 조회해보세요!',
            ins_input_placeholder: 'james라고 쳐보세요.',
            naming_rules: '규칙',
            naming_rules_hide: '좋은 이름을 가지는 방법',
            naming_rules_detail: '- <span class="bg-blue-200 rounded px-1 mx-1">a-z</span>,\n' +
                '<span class="bg-blue-200 rounded px-1 mx-1"> 0-9</span>, 하이픈 (<span class="bg-blue-200 rounded px-1 mx-1">-</span>)' +
                '은 사용 가능합니다.<br>'+
                '- 하이픈(<span class="bg-blue-200 rounded px-1 mx-1">-</span>)은 시작과 끝에 사용될 수 없습니다. <br>\n' +
                '- <span class="bg-red-200 rounded px-1 mx-1">hx</span>문자로는 시작할 수 없습니다. <br>\n' +
                '- 하이픈(<span class="bg-blue-200 rounded px-1 mx-1">-</span>)을 제외한 공백과 모든 특수 문자는 사용할 수 없습니다.(!, $, &, _ 그 외). <br>\n' +
                '- 최소 4자 부터 최대 64자까지 사용할 수 있습니다. <br>\n' +
                '- 이름은 대소문자 구분이 없습니다.<br>',
            pricing: '비용',
            pricing_hide: '기간 별 INS 소유 비용',
            reward_card_desc: '이 프로그램은 INS 구매자를 위한 보상 프로그램입니다.<br>' +
                'Reward Card로 참여 가능하고, 당첨자는 누적된 전체 보상금을 즉시 수령합니다. <br>' +
                '<br><span class="ubuntu-bold text-xl">Reward Card는 어떻게 획득하나요?</span> </br>' +
                '<span class="ml-3"> 구매한 INS와 비례해서 획득 할 수 있으며, <br> </span>' +
                '<span class="ml-3"> Reward Card 1장은 INS 수익금의 절반인 1 ICX와 같은 가치를 가집니다. </br></span>' +
                '<span class="ml-3"><i class="md:text-sm">* 지급 예)INS 2개를 12개월씩 구매하면, 2개 * 12개월 = 24장 획득 </i> </br></span>' +
                '</span>'+

                '<br><span class="ubuntu-bold text-xl">어떤 방법으로 참여할 수 있나요?</span> </br>' +
                '<span class="ml-3"> 1개의 카드로 원하는 회 차에 1번 참여 할 수 있으며, </span><br>' +
                '<span class="ml-3"> 누적된 보상금과 추첨 내역은 ICON Network의 SCORE를 통해 투명하게 공개됩니다.</span></br>'+

                '<br><span class="ubuntu-bold text-xl">보상 프로그램은 어떻게 당첨되나요?</span> </br>' +
                '<span class="ml-3">매 회 SCORE에서 무작위로 하나의 당첨 TX를 생성합니다. </span> </br>'+
                '<span class="ml-3"> 참여자를 위한 또 하나의 무작위 TX를 생성합니다.</span></br>' +
                '<span class="ml-3"> 당첨 TX의 참여자 설정된 난이도에 따라 TX의 hash가 일치하면 당첨! </span> </br>'+
                '<span class="ml-3"> 이전회차에 당첨자가 없을 경우, 모집금액은 다음 회차로 이월됩니다. </span></br><br>'+
                '<ul class="list-disc list-inside text-gray-500 -pl-20">' +
                '<li> 초기에는 난이도(Hash 자릿수)를 낮춰서 당첨 확률을 높일 계획입니다.  </li>'+
                '<li> 매 응모마다 <span class="bg-red-200 rounded px-1 mx-1">1 ICX</span>씩 누적되며, 최종 당첨자가 누적된 모든 ICX를 가집니다.</li>'+
                '</ul>'+
                '<i class="md:text-sm">* 당첨 예) 100명이 응모 했을 경우에, 해당 회차에 모집된 금액은 <br>이전 회차 까지의 누적 당첨금 + 100 ICX) 이고 당첨 여부는 즉시 확인 됩니다.</i><br>'+
                '<br> <span class="ubuntu-bold"> INS도 소유하고 보상 프로그램으로 ICX도 획득하세요! </span>' ,

            security_title: "Multiple Layers of Security",
            security_desc: "WalletX는 지문 인증으로 자금이 안전하고 거래가 안전한지 확인할 수 있는 다양한 방법을 제공합니다.",
            notification_title: "Notification",
            notification_desc: "자산 변경시 확인이 쉽도록 모바일 뱅킹 서비스 같은 푸시 알림 기능을 제공합니다. 사용자는 이벤트에 대한 실시간 알림을 받을 수 있습니다.",
            icontrol_intro: 'ICONTROL 팀은 블록체인을 통해 세상을 하나씩 바꾸는 프로젝트를 설계, 개발 및 계획합니다. '+
                '우리 팀은 ICON Network의  열렬한 지지자로, ICON 생태계에 기여하기 위해 뭉쳤습니다.<br>' +
                'WalletX는 더 안전하고 편한 지갑을 만들기 위한 도전에서 시작된 결과물입니다. <br>' +
                'WalletX는 "블록체인의 실용화"를 위한 플랫폼이 될 것 이며, 이런 우리의 도전과 성과에 대해서 계속 귀를 기울여 주시고 응원해주세요. <br><br> ' +
                '감사합니다.'

        }
    }
}

module.exports= messages
