<template>
  <div class="overflow-x-hidden">
    <Header />
    <div>
      <div class="container mx-auto flex md:min-h-screen">

        <div class="flex md:mb-64 items-center mx-auto z-10 xs:h-100">
          <div
            class="inline-flex flex-col md:flex-row flex-no-wrap md:items-center relative"
          >
            <div class="md:w-1/2 px-6 md:px-8 flex flex-col md:items-start  mt-16">
              <p class="subpixel-antialiased title_text ubuntu-bold ">
                <span class="walletx_gradient mr-3"> {{ $t("message.secure") }}</span>
                <span > {{ $t("message.main_title") }}</span>
              </p>
<!--              style="color:#6B7280;"-->
              <p
                class="mt-8 text-lg lg:text-lg max-w-md leading-tight text-black md:text-gray-500 sm:text-black"

                v-html='$t("message.main_sub_title")'
              >
              </p>
              <div
                class="mt-4 text-base lg:text-lg max-w-md le
                ading-snug ubuntu-bold"
              >
                <span>{{ $t("message.main_comming") }}</span>

              </div>



              <transition appear name="fade">
                <div class="inline-flex flex-row mt-0 xs:mt-10">

                  <a
                    class="mt-8 grow--button"
                    href="https://apps.apple.com/kr/app/wallet-x-for-icon/id1575342820"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      class="w-40 cursor-pointer"
                      src="@/assets/img/icon/app-store.png"
                      alt="app store image"
                    />
                  </a>
                  <a
                    class="mt-8 ml-8 grow--button"
                    href="https://play.google.com/store/apps/details?id=id.icontrol.walletx"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      class="w-40 cursor-pointer"
                      src="@/assets/img/icon/google-play.png"
                      alt="google play image"
                    />
                  </a>
                </div>
              </transition>
            </div>
            <div class="md:w-1/2 md:px-16 mt-8 block md:hidden xs:mt-20">
              <transition appear name="fade">
                <div>
                  <img
                    class="mt-8 block md:hidden"
                    src="@/assets/img/walletx_half.png"
                    alt="walletx lv image"
                  />
                </div>
              </transition>
            </div>
            <div class="md:w-1/1 md:px-1 hidden md:block mt-24">
              <transition appear name="fade">
                <div>
                  <div class="main_mockup">
                  <z-text class="main_mockup" :layers="25" :depth="'20px'" :direction="'both'" :event="'pointer'" :eventRotation="'50deg'">
                    <img
                      src="@/assets/img/walletx_mockup.png"
                      width="280"
                      title="WalletX main"
                      alt="WalletX main"
                    />
                  </z-text>
                  </div>
<!--                  <div class="main_background">-->
<!--                  <z-text class="main_background" :layers="5" :depth="'20px'" :direction="'backwards'" :event="'pointer'" :eventRotation="'15deg'">-->
<!--                    <img-->
<!--                      src="@/assets/img/purple_round@0.7x.png"-->
<!--                      width="350"-->
<!--                      title="WalletX main"-->
<!--                      alt="WalletX main"-->
<!--                    />-->
<!--                  </z-text>-->
<!--                  </div>-->
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
<!--      <div style="" class="relative absolute" >-->
<!--        <div style="bottom: 5em" class=" absolute w-full" >-->
      <div style="" class="bottom-0 absolute w-full" >
         <svg width="100%" height="500px" version="1.1" xmlns="http://www.w3.org/2000/svg">
           <defs>
             <linearGradient id="gradient1" x1="0%" x2="100%" y1="0%" y2="0%">
               <stop offset="0%" stop-color="#9B46DD"  />
               <stop offset="100%" stop-color="#3D8DD6"  />
             </linearGradient>

             <linearGradient id="gradient2" x1="0" x2="100%" y1="0" y2="0">
               <stop offset="0%" stop-color="#3D8DD6" stop-opacity="0.1" />
               <stop offset="100%" stop-color="#7e57c2" stop-opacity="0.3" />
             </linearGradient>

           </defs>
           <path id="wave_svg" d=""/></svg>
      </div>

      <div style="" class="bottom-0 absolute w-full" >
        <svg width="100%" height="500px" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs></defs><path id="wave_svg2" d=""/></svg>
      </div>
    </div>

    <div id="wallet" class="flex px-8 md:px-0">
      <div class="mx-auto flex flex-col flex-no-wrap items-center mt-24 mb-24">
        <h1
            class="text-2xl md:text-4xl text-center leading-tight ubuntu-bold"
        >
          Introducing WalletX
        </h1>
        <p
            class="mt-4 text-center text-base md:text-xl lg:text-xl open-sans-regular leading-snug md:max-w-4xl md:px-32"
        >
        </p>
        <p class="ubuntu-regular mt-4 text-center text-base md:text-xl lg:text-xl open-sans-regular leading-snug md:max-w-8xl md:px-4"
           v-html="$t('message.introducing')">
        </p>
        <div
            class="mt-16 md:max-w-7xl flex flex-row items-center justify-around -mx-16 md:-mx-10"
        >
          <div
              class="hidden md:block mr-5 reveal-left-right">
            <img src="@/assets/img/intro/wallet_main.png" alt="" />
          </div>
          <div
              class="hidden md:block reveal-left-right">
            <img src="@/assets/img/intro/wallet_list.png" alt=""/>
          </div>
          <div
              class="hidden md:block reveal-left-right">
            <img src="@/assets/img/intro/finger_print.png" alt=""/>
          </div>
          <div
              class="hidden md:block max-w-7xl w-1/2">
            <img src="@/assets/img/intro/introducing_center.png" class="shadowed" alt=""/>
          </div>
          <div
              class="md:ml-7 reveal-static" >
            <img src="@/assets/img/intro/prep_list.png" alt=""/>
          </div>
          <div
              class="ml-2 md:ml-6 reveal-left-right" >
            <img src="@/assets/img/intro/ins_list.png" alt="" />
          </div>
          <div
              class="ml-2 md:ml-6 reveal-left-right">
            <img src="@/assets/img/intro/reward.png" alt=""/>
          </div>
        </div>
      </div>
    </div>

    <div id="ins" class="flex background--blue px-8 md:px-0">

<!--      <div class="container mx-auto flex flex-col items-center mt-24 mb-24 md:mb-32">-->
      <div class="container flex flex-col items-center mt-24 mb-24 md:mb-32 mx-5 mx-auto">
        <div class="flex flex-col items-center ">
          <div class="z-40 flex flex-col-reverse md:flex-row items-center">
            <div class="order-last md:order-first flex flex-col md:items-start max-w-xl md:px-3 text-left">
              <h2 class="mt-8 md:mt-0 text-2xl md:text-2xl header-color ubuntu-bold">
                INS
              </h2>
              <h1 class="mt-8 md:mt-0 text-2xl md:text-5xl header-color ubuntu-bold">
                ICON Name Service
              </h1>

              <p class="mt-2 text-base md:text-lg "
                 v-html="$t('message.ins_title')">
              </p>
              <br>
              <p class="mt-2 text-base md:text-lg md:px-0 pt-5"
                 v-html="$t('message.ins_is_avail')">

              </p>
              <!--                       placeholder="Search for an INS"-->
              <div class="relative flex mt-2 md:text-lg md:px-0 w-full">
                <input class="bg-transparent border-b m-auto block border-gray-500 w-full pl-2 mb-2 text-gray-700 pb-1" type="text"
                       v-bind:placeholder="$t('message.ins_input_placeholder')"
                       v-model="selected_address"
                       @keyup.enter="get_address"

                />
                <span class="border-b block border-gray-500 mb-2">
                  <button @click="get_address" class="w-auto flex justify-end items-center text-black-500 p-2 hover:text-black-900 pr-5">
                    <i class="material-icons">search</i>
                  </button>
                </span>
              </div>

              <div ref="result_elem" class="w-full flex ml-5" v-html="result_message"> </div>
              <div ref="ins_result" id="ins_result" class="w-full flex ml-5"> </div>

            </div>
            <div class="w-3/4 md:w-64 lg:w-96  md:block mt-10 reveal-right-left">
              <img
                  src="@/assets/img/illustrations/whats_ins_2.png"
                  alt=""

              />
            </div>

          </div>

          <div class="mt-10 w-full z-40 sm:ml-10">
            <span
                class="mt-8 md:mt-0 text-lg md:text-3xl md:px-4 header-color ubuntu-bold text-left"
                v-html="$t('message.naming_rules')">
            </span>

            <HideButton v-on:parent_method="toggleMore('NamingRules')">
              <span class="mt-20 text-base md:text-lg text-gray-500 "
                    v-html="$t('message.naming_rules_hide')">

              </span>
            </HideButton>

            <div v-if="hide['NamingRules']" class="text-left">
              <p class="mt-4  text-base md:text-xl lg:text-xl leading-snug md:max-w-4xl md:px-10 text-gray-500"
                 v-html="$t('message.naming_rules_detail')">
              </p>
            </div>
          </div>

          <div class="mt-10 w-full z-40 ml-0 sm:ml-10">
            <span
                class="mt-8 md:mt-0 text-lg md:text-3xl md:px-4 header-color ubuntu-bold text-left"
            >
              {{$t('message.pricing')}}
            </span>
            <HideButton v-on:parent_method="toggleMore('Pricing')">
              <span class="mt-20 text-base md:text-lg text-gray-500"
                    v-html="$t('message.pricing_hide')">
              </span>
              <!--                Determine the price by length of time you want to use the INS-->
            </HideButton>

            <div v-if="hide['Pricing']">
              <table class="text-left  mx-auto md:w-100 text-gray-500 pricing_table ">
                <thead>
                <tr>
                  <th class="py-4 px-5 mr-5 bg-grey-lighter font-sans font-medium uppercase text-sm text-grey border-b border-black text-center">1 Month</th>
                  <th class="py-4 px-5 bg-grey-lighter font-sans font-medium uppercase text-sm text-grey border-b border-black text-center">12 Month</th>
                </tr>
                </thead>
                <tbody>
                <tr class="hover:bg-blue-lightest">
                  <td class="py-4 px-5 border-b border-grey-light text-center"><span class="ubuntu-bold text-xl md:text-4xl">2</span> ICX</td>
                  <td class="py-4 px-5 border-b border-grey-light text-center"><span class="ubuntu-bold text-xl md:text-4xl">20</span> ICX</td>

                </tr>
                <tr class="hover:bg-blue-lightest">
                  <td class="py-4 px-5 border-b border-grey-light text-center">1 INS Ownership <br>1 Reward card</td>
                  <td class="py-4 px-5 border-b border-grey-light text-center">1 INS Ownership <br>12 Reward card</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
<!--        -->
<!--        <vue-particles class="flex absolute container hidden sm:hidden md:block"-->
        <vue-particles style="position: absolute;width: 100%;height: 100%;"
                       color="#5C0000"
                       :particleOpacity="0.7"
                       :particlesNumber="2"
                       shapeType="circle"
                       :particleSize="30"
                       linesColor="#5C0000"
                       :linesWidth="1"
                       :lineLinked="true"
                       :lineOpacity="0.4"
                       :linesDistance="150"
                       :moveSpeed="3"
                       :hoverEffect="true"
                       hoverMode="grab"
                       :clickEffect="true"
                       clickMode="push"
        >
        </vue-particles>

      </div>

    </div>


    <div class="flex px-10 md:px-0">
      <div
          class="container mx-auto flex flex-col items-center mt-24 mb-24 md:mb-32"
      >
        <div class="flex flex-col items-center">
          <div
              class="z-40 flex flex-col-reverse md:flex-row items-center"
          >
<!--            class="order-last md:order-first flex flex-col md:items-start max-w-lg px-5 md:px-0 sm:text-left md:text-left sm:w-full mb-5"-->
            <div
                class="order-last md:order-first flex flex-col md:items-start max-w-xl px-0 md:px-0 text-left md:pr-0 sm:pr-0">
              <h1
                  class="mt-8 md:mt-0 text-xl md:text-5xl md:px-0 header-color ubuntu-bold"
              >
                Reward Card 💸
              </h1>
              <!--              <p class="mt-2 text-base md:text-left leading-snug text-gray-600 text-left"-->
<!--              whitespace-pre-->
              <p class="mt-2 text-base leading-snug"
                 v-html="$t('message.reward_card_desc')">
              </p>
              <br>
            </div>
            <div class="md:w-64 lg:w-96 md:px-1  md:block">
              <img
                  src="@/assets/img/illustrations/reward_program.png"
                  alt=""
                  class="px-3 reveal-right-left"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="features" class="flex background--blue">
      <div
          class="mx-auto mt-16 md:mb-32 md:mt-32 flex flex-col-reverse md:flex-row items-center"
      >
        <div class="relative md:w-80 lg:w-96 reveal-left-right" >
          <img
              src="@/assets/img/secure_app.png"
              alt=""
          />
        </div>
        <div
            class="col-start-1 items-center md:items-start max-w-xl px-5 md:px-0 text-center md:text-left md:ml-20 "
        >
          <h1 class="mt-8 md:mt-0 md:text-5xl md:px-4 md:w-96 lg:w-full header-color ubuntu-bold text-xl"
              v-html="$t('message.security_title')"
          >
          </h1>
          <p class="mt-10 text-base md:text-lg md:px-4 ubuntu-regular "
             v-html="$t('message.security_desc')"
          >
          </p>
        </div>
      </div>
    </div>

    <div class="flex">
      <div
        class="mx-auto mt-16 md:mb-32 md:mt-32 flex flex-col-reverse md:flex-row items-center "
      >
        <!--          <div class="md:w-48 lg:w-64 lg:w-6">-->
        <div class="relative md:w-48 lg:w-96 pl-4 reveal-left-right">
          <img
            src="@/assets/img/notification_app.png"
            alt=""
          />
        </div>
        <div
          class="flex flex-col items-center md:items-start  max-w-xl px-5 md:px-0 text-center md:text-left md:ml-10"
        >
          <h1 class="mt-8 md:mt-0 text-xl md:text-5xl md:px-4 header-color ubuntu-bold "
            v-html="$t('message.notification_title')"
          >
          </h1>
          <p class="mt-10 text-base md:text-lg md:px-4 ubuntu-regular"
             v-html="$t('message.notification_desc')"
          >
          </p>

        </div>
      </div>
    </div>
    <div id="last" class="flex ">
      <div
        class="container mx-auto flex flex-col items-center mt-24 mb-24 md:mb-32"
      >
        <div
          class="md:mt-16 inline-flex flex-col-reverse lg:flex-row px-6 md:px-0 flex-no-wrap items-center justify-center"
        >
          <div
            class="md:w-1/2 flex flex-col md:max-w-lg md:items-start md:pl-6 md:pr-8"
          >
            <h1
              class="ubuntu-bold mt-16 text-center md:text-left lg:mt-0 text-2xl md:text-4xl gt-walsheim-medium leading-tight"
            >
              ICONTROL
            </h1>
            <h2
              class="mt-4 text-center md:text-left text-xl md:text-2xl gt-walsheim-regular leading-tight px-6 md:px-0"
            >
            </h2>
            <div
              class="ubuntu-regular mt-4 text-center md:text-left text-base md:text-base lg:text-sm open-sans-regular leading-normal"
              v-html="$t('message.icontrol_intro')"
            >

            </div>
          </div>
          <div
            class="md:w-1/3 max-w-5xl px-20 md:px-20"
          >
            <z-text class="main_mockup z-spinner"  :layers="30" :depth="'30px'" :direction="'forwards'"  :eventRotation="'50deg'">
            <img src="@/assets/img/icontrol_logo.png" alt="" />
            </z-text>
            <z-text class="main_mockup z-spinner"  :layers="30" :depth="'30px'" :direction="'forwards'"  :eventRotation="'50deg'">
              <img src="@/assets/img/walletx_logo.png" alt="" />
            </z-text>
          </div>


        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import HideButton from "@/components/HideButton.vue";

import scrollReveal from 'scrollreveal';
const wavify = require('@/lib/wavify');

export default {
  name: "Home",
  components: {
    Header,
    Footer,
    HideButton,
    // VueWavify
  },
  data() {
    return{
      selected_address: "",
      result_message: "",
      isHidden: false,
      isHiddenNamingRules: false,
      isHiddenPricing: false,
      scrollReveal: scrollReveal(),
      hide: {
        NamingRules: false,
        Pricing: false
      }
    }
  },
  methods: {
    get_address: function() {
      const INS_SDK = require("../ins-sdk-js")
      const ins_api = new INS_SDK.INS({network: "mainnet"});
      let error_cnt = 0;
      let result_element_id = "ins_result";
      let i18n = this.$root.$i18n;

      this.$refs.result_elem.html = "";
      this.$refs.ins_result.innerText = "";

      this.selected_address = this.selected_address.toLowerCase().trim()

      let naming_rule_check = {};
      if (this.selected_address.length<4) {
          error_cnt++;
          naming_rule_check.length_error = "<span class='bg-red-200 rounded px-1 mx-1'>" +
                                      this.selected_address.length + "</span>"+ i18n.t("INS.length_error")
      }

      if (this.selected_address.length> 64) {
        error_cnt++;
        naming_rule_check.length_error = this.selected_address.length + i18n.t("INS.length_error")
      }

      if (this.selected_address.startsWith("hx")) {
        error_cnt++;
        naming_rule_check.not_allowed_hx_string  =  "<span class='bg-red-200 rounded px-1 mx-1'>" +
                                                    this.selected_address  + "</span>"+ i18n.t("INS.not_allowed_hx_string")
      }

      if (this.selected_address.startsWith("cx")) {
        error_cnt++;
        naming_rule_check.not_allowed_cx_string  =  "<span class='bg-red-200 rounded px-1 mx-1'>" +
            this.selected_address  + "</span>"+ i18n.t("INS.not_allowed_cx_string")
      }


      let pattern_num = /[0-9]/;	// 숫자
      let pattern_eng = /[a-zA-Z]/;	// 문자
      let pattern_spc = /[~!@#$%^&*()_+|<>?:{}]/; // 특수문자
      let pattern_kor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; // 한글체크
      let pattern_blank = /[\s]/g;
      let hyphen_trim = /^\-|$\-/g;

      if( hyphen_trim.test( this.selected_address) === true){
        error_cnt++;
        naming_rule_check.not_allowed_position  =  "<span class='bg-red-200 rounded px-1 mx-1'>" +
          this.selected_address  + "</span>"+ i18n.t("INS.hyphen_error")
      }else if( pattern_blank.test( this.selected_address) === true){
        error_cnt++;
        naming_rule_check.not_allowed_blank  =  "<span class='bg-red-200 rounded px-1 mx-1'>" +
            this.selected_address  + "</span>"+ i18n.t("INS.not_allowed_blank")
      }
      else if( (pattern_spc.test(this.selected_address)) ||
          (pattern_kor.test(this.selected_address))
      ){
        error_cnt++;
        naming_rule_check.not_allowed_characters = "<span class='bg-red-200 rounded px-1 mx-1'>" +
            this.selected_address + "</span> is not permitted. "
      }

      if (Object.keys(naming_rule_check).length) {
        this.result_message = "<span class='bg-red-200 rounded px-0 mx-0'>" +i18n.t('INS.invalid_ins') + "</span>";
        // this.result_message = this.$i18n('message.ins_error_msg'); //"Invalidate Rules"
        INS_SDK.obj2Table({elem: result_element_id, data: naming_rule_check});

        return
      }

      ins_api.getNameStatus(this.selected_address).then(r=>{
            // document.getElementById('ins_result').innerHTML=r.response.result;
            // if (r.response.status === 200) {
              if(r.response.result.reserved === "True") {
                this.result_message = "<span class='mt-2'><span class=\"bg-green-200 rounded px-1 mx-0\">"+i18n.t('INS.reserved_ins') + "</span>";
                delete r.response.result['address_update_date'];
                INS_SDK.obj2Table({elem: result_element_id, data: r.response.result});
              } else if(r.response.result.address) {
                this.result_message = "<span class='mt-2'><span class=\"bg-green-200 rounded px-1 mx-0\">"+i18n.t('INS.used_ins') + "</span>";
                delete r.response.result['address_update_date'];
                INS_SDK.obj2Table({elem: result_element_id, data: r.response.result});
              }else{
                this.result_message = "<span class='mt-2'><span class=\"bg-blue-200 rounded px-1 mx-0\">"+ this.selected_address + "</span> "+
                    i18n.t('INS.avail_ins') +
                    "</span>";
              }
            // }

          }
          //
      );


    },
    parentMethod: function (e) {
      this.isHiddenNamingRules = true;
    },
    toggleMore: function(value) {
      this.hide[value] = !this.hide[value];
    },
  },
  mounted() {

    wavify.wavify( document.querySelector('#wave_svg2'), {
      height: 170,
      bones: 5,
      amplitude: 60,
      // color: 'rgba(42, 86, 105)',
      color: 'url(#gradient1)',
      //hsl(274deg 69% 57%) 0%, hsl(202deg 65% 54%) 98.44%)
      // color: "hsl(274deg 69% 57%)",
      speed: .15
    })

    wavify.wavify( document.querySelector('#wave_svg'), {
      height: 150,
      bones: 3,
      amplitude: 40,
      color: 'url(#gradient2)',
      // color: 'rgba(167, 212, 232)',
      // color: 'rgba(255,255,255, 0.20)',

      // color: 'hsl(202deg 65% 54%) 98.44%)',
      speed: .25
    })


    this.scrollReveal.reveal('.reveal-scale', {
        // When animation length
          duration: 1000,
          // delay
          delay: 200,
          // animation start position, 'bottom', 'left', 'top', 'right'
          // origin: 'top',
          // rollback time whether to trigger the animation again
          reset: false,
          // whether to use animation in the mobile terminal
          mobile: false,
          // rolling distance, the unit can be used%, rem, etc.
          // distance: '200px',
          // Other available // animation
          opacity: 0.001,
          easing: 'linear',
          scale: 2,
      });

    this.scrollReveal.reveal('.reveal-right-left', {
      duration: 1000,
      delay: 200,
      origin: 'right',
      reset: false,
      mobile: false,
      distance: '200px',
      opacity: 0.001,
      easing: 'linear',
      scale: 1,
    });


    this.scrollReveal.reveal('.reveal-left-right', {
        duration: 1000,
        delay: 200,
        origin: 'left',
        reset: false,
        mobile: false,
        distance: '200px',
        opacity: 0.001,
        easing: 'linear',
        scale: 1,
      });

      this.scrollReveal.reveal('.reveal-static', {
        // When animation length
        duration: 1000,
        // delay
        delay: 200,
        reset: false,
        mobile: false,
        distance: '0px',
        opacity: 0.001,
        easing: 'linear',
        scale: 1,
      });


      this.$nextTick(function() {
      const ctx = document.querySelector('#particles-js > canvas').getContext('2d');
      let grad;
      function onresize() {
        grad= ctx.createLinearGradient(0,0,ctx.canvas.width,0);
        grad.addColorStop(0,'#9B46DD');
        grad.addColorStop(1,'#3D8DD6');
      }

      // must be ran after Particles.js' own anim loop has began
      // se we are always pushed after their drawings

      function anim() {
        ctx.fillStyle = grad;
        ctx.globalCompositeOperation = "source-atop";
        ctx.fillRect(0,0,ctx.canvas.width,ctx.canvas.height);
        ctx.globalCompositeOperation = "source-over";
        requestAnimationFrame( anim );
      }
      onresize();
      requestAnimationFrame( anim );
      // eslint-disable-next-line no-restricted-globals
      addEventListener('resize', onresize);
    });
    // new Ztextify(".3d_mockup", {
    //   depth: "30px",
    //   layers: 25,
    //   fade: true,
    //   direction: "forwards",
    //   event: "pointer",
    //   eventRotation: "50deg"
    // });

    // ScrollReveal().reveal('.scroll_reveal', {scale: 3, delay: 250, duration: 500});




  }

};
</script>
