import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'

import VueParticles from 'vue-particles'
// import ZText from '@namchee/vue-ztext';

// import VModal from "vue-js-modal";
// import Popover from "vue-js-popover";
import { VueClipboard } from '@soerenmartius/vue3-clipboard'

// import vueSmoothScroll from "vue3-smooth-scroll";
// import VueScrollReveal from "vue-scroll-reveal";
import Vue3SmoothScroll from "vue3-smooth-scroll";
// import App from "./App.vue";
// import router from "./router";
import ZText from '@namchee/vue-ztext';


import "@/assets/css/tailwind.css";
import "@/assets/css/main.css";
import i18n from "./i18n";

const prjson = require("../package.json")

console.log(`app_name: ${prjson.name}, version: ${prjson.version}` )

const app = createApp(App)

app.use(i18n);
app.use(ZText);
app.use(Vue3SmoothScroll);
app.use(VueParticles);
app.use(router);
app.use(VueClipboard);
app.mount('#app');



